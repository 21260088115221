<template>
  <div class="report-table">
    <div
      class="table-vertical white mt-2"
      style="
        display: flex;
        height: calc(100% - 15px);
        overflow: auto;
        width: 100%;
      "
      v-if="direction == 'v'"
    >
      <v-simple-table fixed-header :height="'100%'">
        <thead>
          <tr>
            <th
              v-for="(header, i) in headers"
              :key="i"
              :style="
                'width: ' +
                header.width +
                ';background-color:' +
                (header.value == 'options' ? '#feec00ff' : '')
              "
              :class="header.alignh"
              class="pa-1"
            >
              {{ $t(header.text) }}
              <span v-if="header.unit != ''">{{ header.unit }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, j) in items" :key="j">
            <td
              v-for="(header, k) in headers"
              :key="k"
              :style="`width: ${header.width}; background-color: ${
                header.value == 'options' ? '#feec00ff' : ''
              }; cursor: ${
                header.value == 'options'
                  ? ''
                  : clickableItem
                  ? 'pointer !important;'
                  : ''
              }`"
              :class="header.alignb"
              class="pa-1"

            >
              <slot :name="[header.value]" v-bind="item">
                <template v-if="header.value == 'options'">
                  <v-progress-circular
                    v-if="item.id === idItemLoading"
                    indeterminate
                    class="ore-progress-circular-loading"
                  ></v-progress-circular>
                  <template v-else>
                    <v-icon
                      v-if="optionsButtons.edit"
                      @click="funcEdit(item.id)"
                      class="icon-option"
                      :disabled="disableIcon(item, 'edit')"
                      >{{ readOnly == false ? "mdi-pencil" : "mdi-eye" }}</v-icon
                    >
                    <v-icon
                      v-if="optionsButtons.delete"
                      @click="funcDelete(item.id)"
                      class="icon-option"
                      :disabled="disableIcon(item, 'delete')"
                      >mdi-delete</v-icon
                    >
                  </template>
                </template>
                <template v-else>
                  <template v-if = "header.value == 'product'">
                    <v-select
                        class="product_data"
                        v-model="item.product"
                        :items="materialsSummaryProductList"
                        item-value="id"
                        item-text="name"
                        outlined
                        dense
                        @change = "updatedSelectVal(item)"
                    />
                  </template>
                  <template v-else-if="header.value == 'activity'">
                      <v-select
                          class="activity_data"
                          v-model="item.activity"
                          :items="materialsSummaryActivitiesList"
                          item-value="id"
                          item-text="name"
                          outlined
                          dense
                          @change = "updatedSelectVal(item)"
                      />
                  </template>
                  <template v-else>
                      <div v-if="(header.value == 'code') || (header.value == 'total_materials')" class="cuto_cmpt_data">
                        <v-text-field
                          v-model="item[header.value]"
                          single-line
                          autocomplete="off"
                          outlined
                          dense
                          readonly
                        />
                      </div>
                      <div v-else class="qnt_data">
                        <v-text-field
                          v-model="item.qty"
                          :value="item[header.value] != '' ? item[header.value] : ''" 
                          :class="item.id+'_'+header.key+'_field'"
                          @keypress="isNumber"
                          @keyup="updateQtyStatus($event, item.id)"
                          @click="EditTableData(item.id,header.value)"
                          single-line
                          autocomplete="off"
                          outlined
                          dense
                        >
                        </v-text-field>
                      </div>
                  </template>
                </template>
              </slot>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <div
      class="table-horizontal white mt-2"
      style="
        display: flex;
        height: calc(100% - 15px);
        overflow: auto;
        width: 100%;
      "
      v-else
    >
      <div class="tableh-header" style="height: 100%">
          <div
            class="v-data-table table-body v-data-table--dense theme--light"
            style="height: 100%"
          >
          <div
            class="v-data-table__wrapper"
            style="height: 100%; overflow-x: hidden"
          >
            <table :style="'height: ' + heightTableHor">
              <tbody>
                <tr
                  v-for="(header, i) in headers"
                  :key="i"
                  :style="
                    (header.value == 'options' ? 'height: 36px' : '') +
                    ' !important; background-color:' +
                    colorBg +
                    ' !important'
                  "
                >
                  <th
                    :style="
                      'width: 100%; height: 41px !important;color:' +
                      colorText +
                      ' !important;'
                    "
                    style="text-align: left !important"
                  >
                    <template v-if="header.value != 'options'">
                      {{ $t(header.text) }}
                      <span v-if="header.unit != ''">{{ header.unit }}</span>
                    </template>
                    <template v-else>
                      <div class="options-h">
                        <div style="height: 34px">&nbsp;</div>
                      </div>
                    </template>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="tableh-body" style="height: 100%">
        <div
          class="v-data-table table-body v-data-table--dense theme--light"
          style="height: 100%"
        >
          <div class="v-data-table__wrapper" style="height: 100%">
            <table :style="'width: calc(150px * ' + items.length +') !important; height: 100%'">
              <tbody>
                <tr
                  v-for="(header, i) in headers"
                  :key="i"
                  :class="header.alignh"
                  :style="header.value == 'options' ? 'height: 36px' : ''"
                >
                  <td
                    v-for="(item, j) in items"
                    :key="j"
                    :style="`width: 150px; height: auto !important;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; cursor: ${
                      header.value == 'options'
                        ? ''
                        : clickableItem
                        ? 'pointer !important;'
                        : ''
                    };`"
                  >
                    <template v-if="header.value == 'options'">
                      <div
                        class="options-h"
                        :style="
                          'background-color:' +
                          (header.value == 'options' ? '#feec00ff' : '')
                        "
                      >
                        <v-icon
                          v-if="optionsButtons.edit"
                          @click="funcEdit(item.id)"
                          class="icon-option"
                          :disabled="disableIcon(item, 'edit')"
                          >{{ readOnly == false ? "mdi-pencil" : "mdi-eye" }}</v-icon
                        >
                        <v-icon
                          v-if="optionsButtons.delete"
                          @click="funcDelete(item.id)"
                          class="icon-option"
                          :disabled="disableIcon(item, 'delete')"
                          >mdi-delete</v-icon
                        >
                      </div>
                    </template>
                    <template v-else>
                      <template v-if = "header.value == 'product'">
                        <v-select
                            class="product_data"
                            v-model="item.product"
                            :items="materialsSummaryProductList"
                            item-value="id"
                            item-text="name"
                            outlined
                            dense
                            @change = "updatedSelectVal(item)"
                            :hide-details="true"
                        />
                      </template>
                      <template v-else-if="header.value == 'activity'">
                          <v-select
                              class="activity_data"
                              v-model="item.activity"
                              :items="materialsSummaryActivitiesList"
                              item-value="id"
                              item-text="name"
                              outlined
                              dense
                              @change = "updatedSelectVal(item)"
                              :hide-details="true"
                          />
                      </template>
                      <template v-else>
                          <div v-if="(header.value == 'code') || (header.value == 'total_materials')" class="cuto_cmpt_data">
                            <v-text-field
                              v-model="item[header.value]"
                              single-line
                              autocomplete="off"
                              outlined
                              dense
                              readonly
                              :hide-details="true"
                            />
                          </div>
                          <div v-else class="qnt_data">
                            <v-text-field
                              v-model="item.qty"
                              :value="item[header.value] != '' ? item[header.value] : ''" 
                              :class="item.id+'_'+header.key+'_field'"
                              @keypress="isNumber"
                              @keyup="updateQtyStatus($event, item.id)"
                              @click="EditTableData(item.id,header.value)"
                              single-line
                              autocomplete="off"
                              outlined
                              dense
                              :hide-details="true"
                            >
                            </v-text-field>
                          </div>
                      </template>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import configMixins from "@/mixins/config";
import basicMixin from "@/mixins/basic";
import oreMixin from "@/mixins/ore";
import { getListItemsByCode } from "@/gateway/lists";
import {
  updateMaterialCrushingActivities,
  getMaterialCrushingActivities
} from "@/gateway/material_crushing_activities";

export default {
  name: "report-table",
  mixins: [configMixins,oreMixin,basicMixin],
  data() {
    return {
        productItemsList: [],
        activitiesItemsList: [],  
        materialsSummaryProductList: [],
        materialsSummaryActivitiesList: [],
        materialsSummaryActivitiesItems: [],
        materialsSummaryItems: [],
        selectedItem: null,
        selectedId: "",
        fieldName: "",
        data: {},
        isFormUpdated: false,
        materialSelected: {
          order: 0,
          scenario: "",
          source_desc: "",
          primary_crushing_qty: 0,
          blasting_tons: 0,
          number_of_loads: 0,
          uuid: "",
        },
        activitiesItemsAlertData: {
          ReCrushing: {
            id: 0,
            state: false,
            alert: false,
          },
          Incorporation: {
            id: 0,
            state: false,
            alert: false,
          },
          Reprosesing: {
            id: 0,
            state: false,
            alert: false,
          },
        },
    };
  },
  props: {
    direction: {
      type: String,
      default: "v",
    },
    tableHeight: {
      type: String,
      default: "100%",
    },
    headers: {
      type: Array,
      default: (value) => {
        return [];
      },
    },
    currentProductList:{
      type:Array,
      default:(value)=>{
        return [];
      }
    },
    items: {
      type: Array,
      default: (value) => {
        return [];
      },
    },
    optionsButtons: {
      type: Object,
      default: (value) => {
        return {
          edit: true,
          delete: true,
        };
      },
    },
    funcEdit: {
      type: Function,
      default: (value) => {
        return value;
      },
    },
    funcDelete: {
      type: Function,
      default: (value) => {
        return value;
      },
    },
    idItemLoading: {
      type: Number,
      default: null,
    },
    colorBg: {
      type: String,
      default: "#ffffff",
    },
    colorText: {
      type: String,
      default: "#000000",
    },
    clickableItem: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    rowHeight() {
      return "calc(100% / " + this.headers.length + ")";
    },
    heightTableHor() {
      return this.isMobile ? "100%" : "calc(100% - 15px)";
    },
    productItemsListForm() {
    let _items = [];
    this.$_.cloneDeep(this.productItemsList).forEach((_x) => {
      _x["product"] = this.$t("core.products_list." + _x["product"]) + " (" + (_x["product_code"]) + ")";
      _items.push(_x);
    });
    return _items;
    },
  },
  async mounted(){
      this.getLists();
      this.currentProductList.forEach(ev=> ev.isEdited = false);
  },
  methods: {
    isNumber(event) {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
      }
    },
    updateQtyStatus(event, id) {
      const index = this.currentProductList.findIndex(val => val.id === id);
      if(this.currentProductList[index].qty !== Number(event.target.value)|| this.currentProductList[index].isEdited){
        this.currentProductList[index].isEdited = true;
      }else{
        this.currentProductList[index].isEdited = false;
      }
      this.isFormUpdated = this.currentProductList.some(el=> el.isEdited === true)
    },
    updatedSelectVal(item){
      const index = this.currentProductList.findIndex(val => val.id === item.id);
      const foundActivity = this.materialsSummaryActivitiesList.find(activity => activity.name === item.activity);
      const foundProduct = this.materialsSummaryProductList.find(product => product.name === item.product);
      if(foundActivity.new_code !== this.currentProductList[index].activity || foundProduct.new_product_id !== this.currentProductList[index].product || this.currentProductList[index].isEdited){
        this.currentProductList[index].isEdited = true;
      }else{
        this.currentProductList[index].isEdited = false;
      }
      this.isFormUpdated = this.currentProductList.some(el=> el.isEdited === true)
    },
    disableIcon(item, icon) {
      let stateIcon = true;
      try {
        if (icon == "edit") {
          stateIcon = !item.options.edit;
        } else if (icon == "delete") {
          stateIcon = !item.options.delete;
        }
        return stateIcon;
      } catch {
        return false;
      }
    },
    onClickItemDT(item) {
      if (this.clickableItem) {
        this.$emit("onClickItem", item);
      }
    },
    async getLists() {
      this.productItemsList = this.oreGetProductListByCampaign();
      let  rawData = this.productItemsListForm;
      this.materialsSummaryProductList = rawData.map(product => ({
        // id: product.id,
        code: product.product_code,
        name: product.product,
        new_product_id: product.id

      })); 
      try {
        this.activitiesItemsList = this.listTranslate(
          await getListItemsByCode("ACT")
        );
      } catch (error) {
        this.logError(error);
      }
      this.materialsSummaryActivitiesList = this.activitiesItemsList.map((_item) => {
        let _field = _item.code;
        let _objConfig = {
          // id: 0,
          new_code: _item.id,
          name: _item.name,
          activity_verification: false,
          alert_color: false,
        };
        
        try {
          _item["extra_data"] = this.JSONparse(_item["extra_data"]);
        } catch (error) {
          _item["extra_data"] = {};
        }
        // // show an alert
        if ("activity_verification" in _item["extra_data"]) {
          _objConfig["activity_verification"] =
            _item["extra_data"]["activity_verification"] === 0;
        }
        // show a color
        if ("alert_color" in _item["extra_data"]) {
          _objConfig["alert_color"] = _item["extra_data"]["alert_color"] === 0;
        }
        this.activitiesItemsAlertData[_field] = _objConfig;
        return this.activitiesItemsAlertData[_field];
      });
    },
    EditTableData(id,name) {
      this.selectedId = id;
      this.fieldName=name;
    },
    async handleKeyDown(event)  {
      if (event.key === 'Tab') {
        var name = event.target.name;
        const targetValue = event.target.value;
        if(targetValue){
          let data = "";
          let _data = undefined;
          if (this.selectedId!= "" && this.selectedId!= null) {
            data = await getMaterialCrushingActivities(this.selectedId)
            data.qty = targetValue;
              _data = await updateMaterialCrushingActivities(
                this.selectedId,
                data
              );
          }
        }
        event.preventDefault(); 
        const inputs = document.querySelectorAll('input[type="text"]');
        const index = Array.prototype.indexOf.call(inputs, event.target);
        if (index >= 0 && index < inputs.length - 1) {
          inputs[index + 1].focus();
        }
      }
    },
    async handleActivityChange(item){ 
      let data = "";
      let _data = undefined;
      if (item.id!= "" && item.id!= null) {
        data = await getMaterialCrushingActivities(item.id)
        const foundActivity = this.materialsSummaryActivitiesList.find(activity => activity.name === item.activity);
        data.activity = foundActivity.new_code;
          _data = await updateMaterialCrushingActivities(
            item.id,
            data
          );
      }
    },
    async handleProductChange(items){
      let data = "";
      let _data = undefined;
      if (items.id!= "" && items.id!= null) {
        data = await getMaterialCrushingActivities(items.id)
        const foundProduct = this.materialsSummaryProductList.find(product => product.name === items.product);
        data.product = foundProduct.new_product_id;
          _data = await updateMaterialCrushingActivities(
            items.id,
            data
          );
      }
    },
  },
  watch: {  
    "storeLang.lang"(value) {
      if (value) {
        this.getLists();
      }
    },
  },
};

</script>

<style lang="scss">
.v-data-table.v-data-table--fixed-header.theme--light {
  width: 100%;
}
.table-vertical {
  .v-data-table__wrapper > table {
    border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
  }
  .qnt_data{
    width:150px
  }
  .cuto_cmpt_data{
    width:150px
  }
  .qnt_data:hover{
    background-color: rgb(254, 236, 0);
  }
  .cuto_cmpt_data:hover{
    background-color: rgb(254, 236, 0);
  }
  :is(button,input):focus-visible {
    font-size: 20px;
  }
  .product_data:hover {
    background-color: rgb(254, 236, 0);
  }
  .activity_data:hover {
    background-color: rgb(254, 236, 0);
  }
}
</style>

<style lang="scss" scoped>
.table-horizontal {
  .tableh-header {
    width: 220px;
    float: left;
  }
  .tableh-body {
    float: left;
    width: calc(100% - 220px);
    .v-data-table__wrapper {
      overflow-y: scroll !important;
    }
    .v-data-table__wrapper::-webkit-scrollbar {
      height: 15px !important;
      background-color: #f5f5f5 !important;
    }
    .v-data-table__wrapper::-webkit-scrollbar-thumb {
      width: 20px;
      border-radius: 20px;
      background-color: #c5c5c5;
    }
    .v-data-table__wrapper > table {
      border-right: thin solid rgba(0, 0, 0, 0.12);
    }
    .qnt_data:hover{
      background-color: rgb(254, 236, 0);
    }
    .cuto_cmpt_data:hover{
      background-color: rgb(254, 236, 0);
    }
    :is(button,input):focus-visible {
      font-size: 20px;
    }
    .product_data:hover {
      background-color: rgb(254, 236, 0);
    }
    .activity_data:hover {
      background-color: rgb(254, 236, 0);
    }      
  }
}
</style>