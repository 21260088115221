<template>
  <div>
    <exp-loading v-show="isLoading" />
    <material-form
      ref="materialForm"
      @parentActions="clkFunction"
      :sourceList="sourceItemsListForm"
      :configForms="configForms"
      :scenarioTitle="scenarioReport.name"
    />
    <material-form-products
      ref="materialFormProducts"
      @parentActions="clkFunction"
      :parentData="materialSelected"
      :configForms="configForms"
      :disabledBtnReport="storeOnlyReadReport"
      :materialsSummaryActivitiesList="activitiesItemsList"
      :materialsSummaryProductList="productItemsListForm"
    />
    <material-summary-modal
      ref="materialSummaryModal"
      @parentActions="clkFunction"
      :parentModuleConfig="moduleConfig"
      :materialsSummaryItems="materialsSummaryItemsDT"
      :sourceList="sourceItemsList"
    />
    <material-summary-products-modal
      ref="materialSummaryProductsModal"
      :configForms="configForms"
      :disabledBtnReport="storeOnlyReadReport"
      :productItemsDT="summaryProductsItemsDT"
      :parentModuleConfig="moduleConfig"
    />
    <material-summary-activities-modal
      ref="materialSummaryActivitiesModal"
      :configForms="configForms"
      :disabledBtnReport="storeOnlyReadReport"
      :activitiesItemsDT="summaryActivitiesItemsDT"
      :recrushinGlobalData="recrushinGlobalData"
      :parentModuleConfig="moduleConfig"
    />
    <exp-alert-confirm
      ref="deleteMaterialActivity"
      :colorCancel="'green'"
      :message="msg_delete"
      :fnOk="deleteMaterialActivityFntModal"
    />
    <exp-alert-confirm
      ref="recrushingAlert"
      :colorCancel="'green'"
      message="ore.report.msg_material_imbalance"
      :fnOk="()=>this.$refs.expHeader.isValidForm = true"
    />
    <exp-alert-confirm
      ref="modalSaveChanges"
      :message="'ore.report.msg_data_no_save'"
      :labelOk="'core.comun.yes'"
      :colorOk="'green'"
      :iconOk="'mdi-check-circle'"
      :fnOk="onSaveModuleValidRecrushing"
      :labelCancel="'core.comun.no'"
      :colorCancel="'red'"
      :iconCancel="'mdi-close-circle'"
      :fnCancel="dontSaveChange"
    />
    <v-card
      elevation="0"
      :color="'transparent'"
      id="newMaterial"
      :height="'700px'"
      style="overflow: auto"
    >
      <exp-report-header
        :color="moduleConfig.color"
        :code="''"
        :colorText="moduleConfig.color_text"
        :disabledBtnReport="storeOnlyReadReport"
        ref="expHeader"
        @onSave="onSaveModuleValidRecrushing()"
      />
      <v-card-text class="py-0">
        <v-row no-gutters class="pa-1">
          <v-col cols="4" class="d-inline-flex align-left">
            <v-btn
              rounded
              :color="moduleConfig.color"
              @click="clkFunction('_new_material', undefined)"
              :style="`color: ${
                moduleConfig.color_text != undefined &&
                moduleConfig.color_text != ''
                  ? moduleConfig.color_text
                  : 'black'
              };`"
              large
              :disabled="storeOnlyReadReport"
            >
              <v-icon left>mdi-file-document-outline</v-icon>
              {{ $t("ore.report.add_new") }}
            </v-btn>
          </v-col>
          <v-col cols="5" class="text-right">
            <v-btn
              v-if="configForms.btn_summary_products.visible"
              rounded
              :color="moduleConfig.color"
              @click="$refs.materialSummaryProductsModal.showDialog = true"
              :style="`color: ${
                moduleConfig.color_text != undefined &&
                moduleConfig.color_text != ''
                  ? moduleConfig.color_text
                  : 'black'
              };`"
              large
            >
              <v-icon left>mdi-file-document-outline</v-icon>
              {{ $t("ore.report.summary_products") }}
            </v-btn>
          </v-col>
          <v-col cols="3" class="text-right">
            <v-btn
              rounded
              @click="$refs.materialSummaryModal.showDialog = true"
              :style="`color: black`"
              large
            >
              {{
                `${$t("ore.report.materials")} (${materialSelected.order}/${
                  materialsSummaryItems.length
                })`
              }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters class="pa-1">
          <v-col cols="5" class="text-left">
            <v-row no-gutters class="d-flex align-center">
              <v-col cols="3" class="font-weight-bold">{{
                `${$t("ore.report.scenario")}:`
              }}</v-col>
              <v-col cols="9">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div class="text_ellipsis" v-on="on">
                      {{ `${scenarioReport.name}` }}
                    </div>
                  </template>
                  <span>{{ `${scenarioReport.name}` }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row v-if="configForms.source.visible" no-gutters class="d-flex align-center">
              <v-col cols="3" class="font-weight-bold">{{ `${$t("ore.report.source")}:` }}</v-col>
              <v-col cols="9">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div class="text_ellipsis" v-on="on">
                      {{ `${materialSelected.source_desc}` }}
                    </div>
                  </template>
                  <span>{{ `${materialSelected.source_desc}` }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4" class="text-right">
            <v-btn
              v-if="configForms.btn_summary_activities.visible"
              rounded
              :color="isRecrushingColor ? 'red' : moduleConfig.color"
              @click="$refs.materialSummaryActivitiesModal.showDialog = true"
              :style="`color: ${fastValidValue(
                'color_text',
                'black',
                moduleConfig
              )};`"
              large
            >
              <v-icon left>mdi-file-document-outline</v-icon>
              {{ $t("ore.report.summary_activities") }}
            </v-btn>
          </v-col>
          <v-col cols="3" class="text-right"></v-col>
        </v-row>
        <v-row no-gutters class="pa-1">
          <v-col cols="4" class="text-left">
            <v-row v-if="!campaignMaterialNoBlasting && configForms.blast_qty.visible" no-gutters class="d-flex align-center">
              <v-col class="font-weight-bold">{{ `${$t("ore.report.blast_qty")}:`}}</v-col>
              <v-col>{{ `${materialSelected.blasting_tons} tons` }}</v-col>
            </v-row>
            <v-row v-if="configForms.primary_crushing_qty.visible" no-gutters class="d-flex">
              <v-col class="font-weight-bold">{{ `${$t("ore.report.primary_crushing_qty")}:` }}</v-col>
              <v-col>{{ `${materialSelected.primary_crushing_qty} tons` }}</v-col>
            </v-row>
          </v-col>
          <v-col cols="5" class="text-left">
            <v-row v-if="!campaignMaterialNoBlasting && configForms.remaining_qty.visible" no-gutters class="d-flex align-center">
              <v-col class="font-weight-bold">{{ `${$t("ore.report.remaining_qty")}:` }}</v-col>
              <v-col>{{ `TBD` }}</v-col>
            </v-row>
            <v-row v-if="!campaignMaterialNoBlasting && configForms.number_loads.visible" no-gutters class="d-flex align-center">
              <v-col class="font-weight-bold">{{ `${$t("ore.report.number_loads")}:` }}</v-col>
              <v-col>{{ `${materialSelected.number_of_loads}` }} </v-col>
            </v-row>
          </v-col>
          <v-col cols="3" class="text-right">
            <v-btn
              rounded
              @click="clkFunction('_new_activity', undefined)"
              :style="`color: black`"
              large
              :disabled="!isset(materialSelected.uuid)"
            >
              <v-icon left>mdi-plus</v-icon>
              {{ `${$t("ore.report.products")}` }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="py-1">
        <v-card flat height="250px" style="display: flex; border: 1px solid">
          <exp-report-material-table
            ref ="productTable"
            class="material-table"
            :style="`width: 100%; margin-top: -8px;`"
            :direction="configTable.direction"
            :headers="configTable.headers"
            :currentProductList = "allProductData"
            :items="materialsSummaryActivitiesItemsDT"
            :funcEdit="(_id) => clkFunction('_dt_action_edit_activity', _id)"
            :funcDelete="(_id) => clkFunction('_dt_action_delete_activity', _id)"
            :readOnly="storeOnlyReadReport"
            :optionsButtons="{ edit: false, delete: !storeOnlyReadReport }"
            :colorBg="moduleConfig.color"
            :colorText="moduleConfig.color_text"
          >
          </exp-report-material-table>
          
        </v-card>
      </v-card-text>
      <v-card-text>
        <v-row dense class="d-flex align-center" v-if="configForms.total_crushed_products.visible">
          <v-col cols="4"></v-col>
          <v-col cols="4">
            <v-card class="pa-1" :style="`background: ${moduleConfig.color}; color: ${moduleConfig.color_text};`" flat tile>
              <span> {{ $t("ore.report.total_crushed_products") }}</span>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-row dense class="d-flex align-center">
              <v-col cols="6">
                <v-card class="pa-1 text-right" :style="`background: ${moduleConfig.color}; color: ${moduleConfig.color_text};`" flat tile>
                  <span>{{ numberToFormatNumeral(materialActivitiesTotalProduced(materialSelected)) }}</span>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card class="pa-1 text-right" :style="`background: ${moduleConfig.color}; color: ${moduleConfig.color_text};`" flat tile>
                  <span>{{ `${numberToPercent(materialActivitiesProportion(undefined, materialSelected))} %`}}</span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row dense class="d-flex align-center" v-if="configForms.total_material_produced.visible">
          <v-col cols="4" class="py-0"></v-col>
          <v-col cols="4" class="py-0">
            <v-card class="pa-1" :style="`background: ${moduleConfig.color}; color: ${moduleConfig.color_text};`" flat tile>
              <span>{{ $t("ore.report.total_material_produced") }} </span>
            </v-card>
          </v-col>
          <v-col cols="4" class="py-0">
            <v-row dense class="d-flex align-center">
              <v-col cols="6">
                <v-card class="pa-1 text-right" :style="`background: ${moduleConfig.color}; color: ${moduleConfig.color_text};`" flat tile >
                  <span>{{ `${numberToFormatNumeral(materialActivitiesTotalProducedPositive(materialSelected))}` }}</span>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card class="pa-1 text-right" :style="`background: ${moduleConfig.color}; color: ${moduleConfig.color_text};`" flat tile>
                  <span>&nbsp;</span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row dense class="d-flex align-center" v-if="!campaignMaterialNoBlasting && configForms.total_crushed_primary_crushing.visible">
          <v-col cols="4" class="pb-0">{{ `${$t("ore.report.ttl_crsh_prd_pmy_crsh_qty")}` }}</v-col>
          <v-col cols="4" class="pb-0">
            <v-card class="pa-1 text-right" :style="`background: ${moduleConfig.color}; color: ${moduleConfig.color_text};`" flat tile>
              <span>{{ `${numberToFormatNumeral(materialActivitiesTotalProducedBalance(materialSelected))}` }}</span>
            </v-card>
          </v-col>
          <v-col cols="4" class="pb-0"></v-col>
        </v-row>
        <v-row dense class="d-flex align-center" v-if="configForms.surge_pile_variation.visible">
          <v-col cols="4" class="pb-0">{{ $t("ore.report.surge_pile_variation") }}</v-col>
          <v-col cols="4" class="pb-0">
            <v-card class="pa-1 text-right" :style="`background: ${moduleConfig.color}; color: ${moduleConfig.color_text};`" flat tile>
              <span>{{ numberToFormatNumeral(summaryActivitiesTotalCrusherProductsPrimaryQty(materialSelected.uuid)) }}</span>
            </v-card>
          </v-col>
          <v-col cols="4"></v-col>
        </v-row>
        <template v-for="_item in Object.keys(recrushinLocalData)" v-if="configForms.verfi_act.visible">
          <v-row
            :key="recrushinLocalData[_item].id"
            v-if="recrushinLocalData[_item].activity_verification"
            dense
            class="d-flex align-center"
          >
            <v-col cols="4" class="pb-0"
              >{{ $t("ore.report.verfi_act") }}
              {{ recrushinLocalData[_item].name }}</v-col
            >
            <v-col cols="4" class="pb-0">
              <v-card
                class="pa-1 text-right"
                :style="`background: ${
                  recrushinLocalData[_item].alert_color &&
                  (recrushinLocalData[_item].value > 0 ||
                    recrushinLocalData[_item].value < 0)
                    ? 'red'
                    : moduleConfig.color
                }; color: ${moduleConfig.color_text};`"
                flat
                tile
              >
                <span>{{
                  `${numberToFormatNumeral(recrushinLocalData[_item].value)}`
                }}</span>
              </v-card></v-col
            >
            <v-col cols="4" class="pb-0"> </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// mixins
import basicMixin from "@/mixins/basic";
import oreMixin from "@/mixins/ore";
import oreModulesHelperMixin from "@/mixins/oreModulesHelper";
import configMixins from "@/mixins/config";

//components
import expReportTable from "@/components/expReportTable";
import expReportMaterialTable from "@/components/expReportMaterialTable.vue";
import materialForm from "./form";
import materialSummaryModal from "./material-summary-modal";
import materialSummaryProductsModal from "./material-summary-products-modal";
import materialSummaryActivitiesModal from "./material-summary-activities-modal";
import materialFormProducts from "./form-products";

// gateways
import {
  getAllMaterialCrushing,
  getMaterialCrushing,
  deleteMaterialCrushing,
} from "@/gateway/material_crushing";
import {
  getAllMaterialCrushingActivities,
  getMaterialCrushingActivities,
  deleteMaterialCrushingActivities,
  updateAllMaterialCrushingActivities
} from "@/gateway/material_crushing_activities";
import { getListItems, getListItemsByCode } from "@/gateway/lists";

export default {
  name: "page-reports-material",
  layout: "ore",
  mixins: [basicMixin, oreMixin, oreModulesHelperMixin, configMixins],
  components: {
    expReportTable,
    materialForm,
    materialSummaryModal,
    materialSummaryProductsModal,
    materialSummaryActivitiesModal,
    materialFormProducts,
    expReportMaterialTable
  },
  data() {
    return {
      isLoading: false,
      moduleConfig: {
        code: "",
        name: "",
        color: "",
        color_soft: "",
        color_text: "",
        icon: "",
        icon_svg: "",
        order: 999,
      },
      nextPage: "",
      allProductData:[],
      materialsDtHeaders: [
        {
          text: "ore.report.activities",
          value: "activity",
          alignh: "text-center",
          alignb: "text-right",
        },
        {
          text: "ore.report.code",
          value: "code",
          width: "180px",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "ore.report.products",
          value: "product",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "ore.report.quantity",
          value: "qty",
          width: "80px",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "ore.report.total_materials",
          value: "total_materials",
          width: "100px",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "core.comun.options",
          value: "options",
          width: "85px",
          alignh: "text-center",
          alignb: "text-center",
        },
      ],
      include_id_list: [],
      materialSelected: {
        order: 0,
        scenario: "",
        source_desc: "",
        primary_crushing_qty: 0,
        blasting_tons: 0,
        number_of_loads: 0,
        uuid: "",
      },
      materialsSummaryItems: [],
      materialsSummaryItemsDT: [],
      materialsSummaryActivitiesItems: [],
      productItemsList: [],
      sourceItemsList: [],
      productsItemsList: [],
      activitiesItemsList: [],
      activitiesItemsAlertData: {
        ReCrushing: {
          id: 0,
          state: false,
          alert: false,
        },
        Incorporation: {
          id: 0,
          state: false,
          alert: false,
        },
        Reprosesing: {
          id: 0,
          state: false,
          alert: false,
        },
      },
      msg_delete: "ore.report.confirm_delete_material",
      openDialogImage: false,
      fab: false,
      configForms: {
        source: { visible: true, disabled: false, },
        blast_qty: { visible: true, disabled: false, },
        primary_crushing_qty: { visible: true, disabled: false, },
        remaining_qty: { visible: true, disabled: false, },
        btn_summary_products: { visible: true, disabled: false, },
        btn_summary_activities: { visible: true, disabled: false, },
        number_loads: { visible: true, disabled: false, },
        total_crushed_products: { visible: true, disabled: false, },
        total_material_produced: { visible: true, disabled: false, },
        total_crushed_primary_crushing: { visible: true, disabled: false, },
        surge_pile_variation: { visible: true, disabled: false, },
        verification_crushing: { visible: true, disabled: false, },
        verfi_act: { visible: true, disabled: false, },
      },
      configTable: {
        direction: "v",
        headers: [],
      },
    };
  },
   beforeRouteLeave(to, from, next) {
    const self = this;
    const isEdited = this.$refs.productTable.isFormUpdated;
    if(isEdited){
      self.nextPage = to.path;
      next(false);
      self.$refs.modalSaveChanges.showDialog = true;
    }else{
      next();
    }
  
  },
  async mounted() {
    // Esto es oreModulesHelperMixinsd
    // this is oreModulesHelperMixinsd
    this.initModuleConfig();
    this.configForms = this.getModuleConfigForm(this.configForms);
    this.configTable = this.getModuleConfigTable(this.materialsDtHeaders);
    this.moduleConfig = this.moduleConfigData;
    //
    await this.getLists();
    await this.getMaterialSummaryData();
    await this.getMaterialSummaryActivitiesData();
    this.selectMaterial();
    const products = await getAllMaterialCrushingActivities(this.reportSelectedId);
    this.allProductData = this.$_.cloneDeep(products);
  },
  methods: {
    async getMaterialSummaryActivitiesData(data = null) {
      this.isLoading = true;
      this.materialsSummaryActivitiesItems = [];
      let allData = data
        ? data
        : this.$store.getters["ore/getReportSelected"]
            .material_crushing_activities;
      try {
        if (!this.isset(allData)) {
          allData = await getAllMaterialCrushingActivities(
            this.reportSelectedId
          );
        }
      } catch (error) {
        this.logError(error);
        allData = [];
      }
      if (allData) {
        this.materialsSummaryActivitiesItems = this.$_.cloneDeep(allData);
        this.allProductData = this.$_.cloneDeep(allData);
        this.$store.commit("ore/setUpdateReportsSelected", {
          material_crushing_activities: allData,
        });
      }
      this.isLoading = false;
    },
    async getMaterialSummaryData(data = null) {
      this.isLoading = true;
      let self = this;
      this.materialsSummaryItems = [];
      let allData = data
        ? data
        : this.$store.getters["ore/getReportSelected"].material_crushing;
      try {
        if (!this.isset(allData)) {
          allData = await getAllMaterialCrushing(this.reportSelectedId);
        }
      } catch (error) {
        this.logError(error);
        allData = [];
      }
      if (allData) {
        allData = this.$_.sortBy(allData, function (o) {
          return self.$moment(o.created);
        }); //.reverse();
        allData.forEach((_item, index) => {
          _item["order"] = index + 1;
        });
        this.materialsSummaryItems = allData;
        this.$store.commit("ore/setUpdateReportsSelected", {
          material_crushing: allData,
        });
      }
      this.isLoading = false;
    },
    setMaterialSummaryDT() {
      this.materialsSummaryItemsDT = [];
      this.materialsSummaryItems.forEach((_itemActivity) => {
        let _xItem = this.$_.cloneDeep(_itemActivity);
        let _sourceItem = this.getSourceItem(_xItem.source);
        _xItem["scenario"] = this.getScenarioItem(_xItem.scenario).name;
        _xItem["source"] = `${
          _sourceItem.id === 999999999 || _sourceItem.id === 999999998
            ? ""
            : this.fastValidValue("blasting_number", "", _sourceItem) + " - "
        }${this.$t(this.fastValidValue("description", "", _sourceItem))}`;
        _xItem["primary_crushing_qty"] = `${this.$t(
          this.fastValidValue("primary_crushing_qty", "", _sourceItem)
        )}`;
        _xItem["number_of_loads"] = `${this.$t(
          this.fastValidValue("number_of_loads", "", _sourceItem)
        )}`;
        _xItem["total_materials_produced"] = this.numberToFormatNumeral(
          this.materialActivitiesTotalProducedPositive(_itemActivity)
        );
        _xItem["proportion"] = this.numberToPercent(
          this.materialSummaryProportionPositive(_itemActivity)
        );
        if(this.isMobile) {
          _xItem["created"] = this.$moment(
            _itemActivity.created,
            "YYYY-MM-DDTHH:mm:ss"
          ).format(this.crusherFormat.format_date + " HH:mm");
        } else {
          _xItem["created"] = this.$moment.utc(
            _itemActivity.created + 'Z0',
            "YYYY-MM-DDTHH:mm:ss"
          ).utc().local().format(this.crusherFormat.format_date + " HH:mm");
        }
        this.materialsSummaryItemsDT.push(_xItem);
      });
    },
    async clkFunction(accion, payload) {
      const self = this;
      let data = undefined;
      switch (accion) {
        case "_new_activity":
          self.$refs.materialFormProducts.data = {};
          self.$refs.materialFormProducts.showDialog = true;
          break;
        case "_dt_action_edit_activity":
          self.isLoading = true;
          try {
            data = await getMaterialCrushingActivities(payload);
            if (data) {
              self.include_id_list.push(data.product);
              // self.$refs.materialSummaryModal.showDialog = false;
              self.$refs.materialFormProducts.data = data;
              self.$refs.materialFormProducts.showDialog = true;
            }
          } catch (error) {
            self.logError(error);
          }
          self.isLoading = false;
          break;
        case "_dt_action_delete_activity":
          this.$refs.deleteMaterialActivity.id = payload;
          this.$refs.deleteMaterialActivity.showDialog = true;
          break;
        case "_new_material":
          self.$refs.materialForm.data = {};
          self.$refs.materialForm.showDialog = true;
          break;
        case "_edit_material":
          self.isLoading = true;
          try {
            data = await getMaterialCrushing(payload);
            if (data) {
              self.include_id_list.push(data.source);
              // self.$refs.materialSummaryModal.showDialog = false;
              self.$refs.materialForm.data = data;
              self.$refs.materialForm.showDialog = true;
            }
          } catch (error) {
            self.logError(error);
          }
          self.isLoading = false;
          break;
        case "_delete_material":
          self.isLoading = true;
          try {
            data = await deleteMaterialCrushing(payload);
            // load activities
            this.allProductData = this.$_.cloneDeep(data);
            
            await self.getMaterialSummaryActivitiesData(
              await getAllMaterialCrushingActivities(this.reportSelectedId)
            );
            self.getMaterialSummaryData(data);
            self.selectMaterial(self.materialSelected);
          } catch (error) {
            self.logError(error);
          }
          self.isLoading = false;
          break;
        case "_select_material":
          self.selectMaterial(payload);
          break;
        case "_set_materials_summary":
          await self.getMaterialSummaryData(payload);
          self.selectMaterial();
          break;
        case "_set_materials_summary_activities":
          await self.getMaterialSummaryActivitiesData(payload);
          self.selectMaterial(self.materialSelected.id);
          break;
        case "_clean_includes_id":
          self.include_id_list = [];
          break;
        default:
          break;
      }
    },
    selectMaterial(id = null) {
      let _material = null;
      if (id) {
        _material = this.materialsSummaryItems.find((_x) => _x.id === id);
      }
      if (!_material) {
        _material = this.$_.maxBy(this.materialsSummaryItems, "order");
      }
      if (_material) {
        _material = { ...{}, ..._material };
        let sourceItem = this.getSourceItem(_material.source);
        let scenarioItem = this.getScenarioItem(_material.scenario);
        _material["source_desc"] = `${
          sourceItem.id === 999999999 || sourceItem.id === 999999998
            ? ""
            : this.fastValidValue("blasting_number", "", sourceItem) + " - "
        }${this.$t(this.fastValidValue("description", "", sourceItem))}`;
        _material["scenario"] = scenarioItem.name;
        _material["blasting_tons"] = this.numberToFormatNumeral(
          sourceItem.blasting_tons
        );
        _material["primary_crushing_qty"] = this.numberToFormatNumeral(
          _material.primary_crushing_qty
        );
        _material["number_of_loads"] = this.numberToFormatNumeral(
          _material.number_of_loads
        );
        this.materialSelected = _material;
      } else
        this.materialSelected = {
          order: 0,
          scenario: "",
          source_desc: "",
          primary_crushing_qty: 0,
          blasting_tons: 0,
          number_of_loads: 0,
          uuid: "",
        };
      this.setMaterialSummaryDT();
    },
    async deleteMaterialActivityFntModal(id) {
      this.isLoading = true;
      try {
        let _data = await deleteMaterialCrushingActivities(id);
        await this.getMaterialSummaryActivitiesData(_data);
        this.setMaterialSummaryDT();
      } catch (error) {
        this.logError(error);
      }
      this.isLoading = false;
    },
    async getLists() {
      this.sourceItemsList = this.oreGetSourceListByCampaign();
      this.productItemsList = this.oreGetProductListByCampaign();
      try {
        this.activitiesItemsList = this.listTranslate(
          await getListItemsByCode("ACT")
        );
      } catch (error) {
        this.logError(error);
      }
      this.activitiesItemsList.forEach((_item) => {
        let _field = _item.code;
        let _objConfig = {
          id: 0,
          name: _item.name,
          activity_verification: false,
          alert_color: false,
        };
        _objConfig["id"] = _item.id;
        try {
          _item["extra_data"] = this.JSONparse(_item["extra_data"]);
        } catch (error) {
          _item["extra_data"] = {};
        }
        // show an alert
        if ("activity_verification" in _item["extra_data"]) {
          _objConfig["activity_verification"] =
            _item["extra_data"]["activity_verification"] === 0;
        }
        // show a color
        if ("alert_color" in _item["extra_data"]) {
          _objConfig["alert_color"] = _item["extra_data"]["alert_color"] === 0;
        }
        this.activitiesItemsAlertData[_field] = _objConfig;
      });
    },
    async onSaveModuleValidRecrushing() {
      this.isLoading = true;
      const allProductData = this.allProductData;
      const updatedProdutData = this.materialsSummaryActivitiesItemsDT
      for(let previousItem = 0; previousItem < allProductData.length; previousItem++){
        for(let currentItem = 0; currentItem < updatedProdutData.length; currentItem++){
          if(allProductData[previousItem].id === updatedProdutData[currentItem].id){
            allProductData[previousItem].qty = parseFloat(String(updatedProdutData[currentItem].qty).replace(/,/g, ""));
            const foundActivity = this.activitiesItemsList.find(activity => activity.name === updatedProdutData[currentItem].activity);
            allProductData[previousItem].activity = foundActivity.id;
            const foundProduct = this.productItemsListForm.find(product => product.product === updatedProdutData[currentItem].product);
            allProductData[previousItem].product = foundProduct.id;
          }
        }
      }
      if(allProductData.length>0){
        const response = await updateAllMaterialCrushingActivities(allProductData);
        // this.allProductData = response;
        this.allProductData = this.$_.cloneDeep(response);
        this.$refs.productTable.isFormUpdated = false;
        if (response && response.length>0) {
          this.materialsSummaryActivitiesItems = this.$_.cloneDeep(response);
          this.$store.commit("ore/setUpdateReportsSelected", {
            material_crushing_activities: response,
          });
        }
      }
      this.isLoading = false
      if (this.isRecrushingColor) {
        this.$refs.recrushingAlert.showDialog = true;
      }else{
        this.$refs.expHeader.isValidForm = true;
      }
      if (this.isset(this.nextPage)) this.openRoute(this.nextPage);
    },
    async dontSaveChange() {
      const self = this;
      this.materialsSummaryActivitiesItems = this.$_.cloneDeep(this.allProductData);
          this.$store.commit("ore/setUpdateReportsSelected", {
            material_crushing_activities: this.allProductData,
          });
      self.$refs.modalSaveChanges.showDialog = false;
      this.$refs.productTable.isFormUpdated = false;
      if (this.isset(this.nextPage)) this.openRoute(this.nextPage);
    },
    getSourceItem(id) {
      let _itemSource = this.storeListSource.find((_x) => _x.id == id);
      if (!_itemSource) {
        _itemSource = {
          description_full: "",
          description: "",
          blasting_tons: 0,
          blasting_number: 0,
        };
      } else {
        _itemSource["description_full"] = `${
          _itemSource.id === 999999999 || _itemSource.id === 999999998
            ? ""
            : this.fastValidValue("blasting_number", "", _itemSource) + " - "
        }${this.$t(this.fastValidValue("description", "", _itemSource))}`;
      }
      return _itemSource;
    },
    getScenarioItem(id) {
      let _itemSource = this.storeListScenario.find((_x) => _x.id == id);
      if (!_itemSource) {
        _itemSource = {
          name: "-",
        };
      }
      return _itemSource;
    },
    getActivityItem(id) {
      let _itemSource = this.activitiesItemsList.find((_x) => _x.id == id);
      if (!_itemSource) {
        _itemSource = {
          code: "",
        };
      }
      return _itemSource;
    },
    getProductItem(id) {
      let _itemProduct = this.productItemsListForm.find(
        (_x) => _x.id == id
      );
      return _itemProduct;
    },
  },
  computed: {
    materialsSummaryActivitiesItemsDT() {
      let _materialsSummaryActivitiesItemsDT = [];
      let _materialSelectedUUID = this.fastValidValue(
        "uuid",
        "",
        this.materialSelected
      );
      if (_materialSelectedUUID != "") {
        this.materialsSummaryActivitiesItems.forEach((_itemActivity) => {
          if (_itemActivity.material_crush_uuid !== _materialSelectedUUID)
            return;

          let _itemDT = this.$_.cloneDeep(_itemActivity);
          let _product = this.getProductItem(_itemActivity.product);
          let _qty = _itemDT["qty"];
          _itemDT["activity"] = this.oreGetTranslationListItem(_itemActivity.activity);
          _itemDT["code"] = _product != null ? _product.product_code : "";
          _itemDT["product"] = _product != null ? _product.product: "";
          _itemDT["qty"] = this.numberToFormatNumeral(_itemDT["qty"]);
          _itemDT["total_materials"] = this.numberToPercent(
            this.materialActivitiesProportion(_itemActivity)
          );
          if (_qty < 0) {
            _itemDT["activity"] =
              "<span style='float:left'>" + _itemDT["activity"] + "</span>";
            _itemDT["qty"] =
              "<span style='color:red'>" + _itemDT["qty"] + "</span>";
            _itemDT["total_materials"] =
              "<span style='color:red'>" +
              _itemDT["total_materials"] +
              "</span>";
          }
          _materialsSummaryActivitiesItemsDT.push(_itemDT);
        });
      }
      return _materialsSummaryActivitiesItemsDT;
    },
    campaignMaterialNoBlasting() {
      return (this.storeGlobalSettingsgModules.campaign_material_no_blasting == true);
    },
    sourceItemsListForm() {
      let _items = [];
      this.$_.cloneDeep(this.sourceItemsList).forEach((_x) => {
        let _materialItem = this.materialsSummaryItems.find(
          (_y) => _y.source === _x.id
        );
        if (this.include_id_list.find((_z) => _z === _x.id) || !_materialItem) {
          _x["description"] = `${
            _x.id === 999999999 || _x.id === 999999998
              ? ""
              : this.fastValidValue("blasting_number", "", _x) + " - "
          }${this.$t(this.fastValidValue("description", "", _x))}`;
          _items.push(_x);
        }
      });
      if (!this.campaignMaterialNoBlasting) {
        _items = _items.filter((i) => i.id != 999999998);
      }
      return _items;
    },
    productItemsListForm() {
      let _items = [];
      this.$_.cloneDeep(this.productItemsList).forEach((_x) => {
        let _activityItem = this.materialsSummaryActivitiesItems.find(
          (_y) =>
            _y.product === _x.product_id &&
            _y.material_crush_uuid === this.materialSelected.uuid
        );
        _x["product"] = this.$t("core.products_list." + _x["product"]) + " (" + (_x["product_code"]) + ")";
        _items.push(_x);
      });
      return _items;
    },
    summaryProductsItemsDT() {
      let activities = this.storeReportSelected.material_crushing_activities;
      let itemsFiltered = this.$_.filter(this.productItemsListForm, (x) => {
        return this.$_.find(activities, (y) => y.product == x.id);
      });
      let _items = [];
      this.$_.cloneDeep(itemsFiltered).forEach((_x) => {
        _x["product"] = _x["product"];
        _x["total_qty"] = this.numberToFormatNumeral(this.materialActivitiesTotalProduced(undefined, _x));
        _x["proportion"] = this.numberToPercent(this.materialSummaryProportion(undefined, _x));
        _items.push(_x);
      });
      return _items;
    },
    summaryActivitiesItemsDT() {
      let _items = [];
      this.materialsSummaryActivitiesItems.forEach((_item) => {
        let _materiaParent = this.materialsSummaryItems.find(
          (_x) => _x.uuid === _item.material_crush_uuid
        );
        let _itemDT = this.$_.cloneDeep(_item);
        if (!_materiaParent) return;

        let _product = this.getProductItem(_item.product)
        _itemDT["source"] = this.$t(this.getSourceItem(_materiaParent.source).description_full);
        _itemDT["code"] = _product != null ? _product.product_code: "";
        _itemDT["product"] = _product != null ? _product.product : "";
        _itemDT["activity"] = this.oreGetTranslationListItem(_item.activity);
        _itemDT["total_materials"] = this.numberToPercent(
          this.materialActivitiesAllProportion(_item)
        );
        _itemDT["qty"] = this.numberToFormatNumeral(_itemDT["qty"])
        _items.push(_itemDT);
      });
      return _items;
    },
    recrushinLocalData() {
      let data = {};
      Object.keys(this.activitiesItemsAlertData).forEach((_item) => {
        let _existActivity = this.materialsSummaryActivitiesItems.filter(
          (_x) =>
            _x.activity === this.activitiesItemsAlertData[_item].id &&
            _x.material_crush_uuid === this.materialSelected.uuid
        );
        let obj = {
          ...this.activitiesItemsAlertData[_item],
          value: 0,
        };
        if (_existActivity.length > 0) {
          obj.value = this.$_.sumBy(_existActivity, (item) => item.qty);
        }
        data[_item] = obj;
      });
      return data;
    },
    recrushinGlobalData() {
      let data = {};
      Object.keys(this.activitiesItemsAlertData).forEach((_item) => {
        let _existActivity = this.materialsSummaryActivitiesItems.filter(
          (_x) => _x.activity === this.activitiesItemsAlertData[_item].id
        );
        let obj = {
          ...this.activitiesItemsAlertData[_item],
          value: 0,
        };
        if (_existActivity.length > 0) {
          _existActivity = _existActivity.filter((_x) =>
            this.materialsSummaryItems.find(
              (_y) => _y.uuid === _x.material_crush_uuid
            )
          );
          obj.value = this.$_.sumBy(_existActivity, (item) => item.qty);
        }
        data[_item] = obj;
      });
      return data;
    },
    isRecrushingColor() {
      let count = 0;
      Object.keys(this.recrushinGlobalData).forEach((_item) => {
        if (
          this.recrushinGlobalData[_item].activity_verification &&
          this.recrushinGlobalData[_item].alert_color &&
          (this.recrushinGlobalData[_item].value > 0 ||
            this.recrushinGlobalData[_item].value < 0)
        ) {
          count = count + 1;
        }
      });
      return count > 0;
    },
    isRecrushingAlert() {
      let count = 0;
      Object.keys(this.recrushinGlobalData).forEach((_item) => {
        if (
          this.recrushinGlobalData[_item].activity_verification &&
          (this.recrushinGlobalData[_item].value > 0 ||
            this.recrushinGlobalData[_item].value < 0)
        ) {
          count = count + 1;
        }
      });
      return count > 0;
    },
    scenarioReport() {
      let _scenario = this.getScenarioSelected();
      if (!_scenario) {
        _scenario = {
          name: "",
        };
      }
      return _scenario;
    },
  },
  watch: {
    "storeLang.lang"(value) {
      if (value) {
        this.getLists();
      }
    },
  },
};
</script>
<style>
.material-table > .table-vertical {
  height: calc(100% - 8px) !important;
}
</style>
